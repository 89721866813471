@use "../../colors.scss" as *;

.payments {
    padding-top: 1.5rem;
    color: white;

    &__header {
        display: flex;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .select {
        min-width: 14rem;

        &:nth-child(2) {
            min-width: 20rem;
        }
    }

    &__container {
        display: flex;
        gap: 1.5rem;
        padding-top: 1.5rem;
    }

    &__col_1 {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    &__col_2 {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 1.5rem;
        border: 0.06rem solid $neutral;
        position: relative;
    }

    &__data_cells {
        display: flex;
        gap: 1.5rem;
    }

    &__data_cell {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border: 0.06rem solid $neutral;
        padding: 1.5rem;
        flex: 1;

        h3 {
            font-size: 1.875rem;
            color: $accent;
        }
    }

    &__chart_container {
        padding: 1.5rem 1.5rem 0 0;
        border: 0.06rem solid $neutral;
        position: relative;
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(white, 0.02);
        backdrop-filter: blur(10px);
        z-index: 10;
    }

    &__list_container {
        padding: 1.5rem;
        border: 0.06rem solid $neutral;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__list_item {
        display: flex;
        justify-content: space-between;

        h4 {
            font-size: 1rem;
        }

        p {
            font-size: 0.875rem;
            color: $neutral;
        }

        span {
            font-size: 1rem;
            color: $accent;
        }
    }

    &__artist {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        img {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            color: $neutral;
        }

        span {
            margin-left: auto;
            color: $accent;
        }
    }

    &__contributors {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__contributor {
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            object-fit: cover;
        }

        img:last-child {
            width: 1.15rem;
            height: 1.15rem;
            cursor: pointer;
            opacity: 0.75;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 1;
            }
        }

        p {
            color: $neutral;
        }

        span {
            margin-left: auto;
            color: $accent;
        }
    }

    &__contributor_info {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
    }

    &__sub {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        .select {
            z-index: 0;
            min-width: 8rem;
        }
    }

    &__balance {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        cursor: pointer;
        font-size: 1rem;
        color: $neutral;
        transition: color 0.3s ease;
        &:hover {
            color: $accent;
        }
    }
}
