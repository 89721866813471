.users {
    padding: 2rem 0;
    margin: 0 auto;
    width: 100%;

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, calc(100% / 3 - 1rem));
        gap: 1.5rem;
    }

    & > input {
        margin-bottom: 2rem;
    }
}

