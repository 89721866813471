@use "../../colors.scss" as *;

.analytics {
    padding-top: 1.5rem;
    color: white;

    &__header {
        display: flex;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .select {
        min-width: 14rem;

        &:nth-child(2) {
            min-width: 20rem;
        }
    }

    &__stats {
        display: flex;
        gap: 1.5rem;
        padding-top: 1.5rem;
    }

    &__stat {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border: 0.06rem solid $neutral;
        padding: 1.5rem;
        flex: 1;

        h3 {
            font-size: 1.875rem;
        }

        p {
            font-size: 1rem;
            color: $neutral;
        }

        &:nth-child(3) h3,
        &:nth-child(4) h3 {
            color: $accent;
        }
    }

    &__chart_container {
        margin-top: 1.5rem;
        padding: 1.5rem 1.5rem 0 0;
        border: 0.06rem solid $neutral;
        position: relative;
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(white, 0.02);
        backdrop-filter: blur(10px);
        z-index: 10;
    }

    &__lists {
        display: flex;
        gap: 1.5rem;
        padding: 1.5rem 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 0.06rem solid $neutral;
        padding: 1.5rem;
        flex: 1;

        h4 {
            font-size: 1.25rem;
            color: $neutral-light;
            margin-bottom: 0.5rem;
        }
    }

    &__play {
        display: flex;
        gap: 1rem;
        align-items: center;

        p:nth-child(1),
        p:nth-child(2) {
            color: $neutral-light;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            object-fit: cover;
        }

        p:nth-child(2) {
            color: $neutral-light;
        }

        span {
            margin-left: auto;
            color: $accent;
        }
    }
}
