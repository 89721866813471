@use "../../colors.scss" as *;

.withdrawal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    flex: 1;

    &__balance {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;

        h2 {
            color: $neutral;
            font-size: 1.25rem;
        }
    }

    &__balance_amount {
        color: $accent;
        font-size: 4rem;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    &__btn {
        background-color: $accent;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        cursor: pointer;
        opacity: 0.9;
        transition: opacity 0.2s ease;

        &--remove {
            background-color: $secondary-accent;
        }

        &:hover {
            opacity: 1;
        }
    }
}
