@use "../../../colors.scss" as *;

.user {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: #111111;
    border: 0.075rem solid transparent;
    transition: 0.2s border ease;

    &:hover {
        border: 0.075rem solid darken($neutral, 15%);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.25rem;
    }
    &__header_img {
        width: 4rem;
        height: 4rem;
        border: 0.075rem solid darken($neutral, 15%);
        border-radius: 50%;
    }

    &__header_left {
        display: flex;
        align-items: center;
        gap: 1.25rem;
        flex: 1;
        overflow: hidden;

        div {
            overflow: hidden;
        }
    }

    &__header_email {
        margin-top: 0.25rem;
        color: #565656;
        display: block;
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    h3 {
        font-size: 1.25rem;
        color: $light;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .select__header {
        padding: 0;
        border: none;
        border-radius: 0;
        font-size: 1rem;
        gap: 0.25rem;
        justify-content: flex-start;
        color: #565656;

        img {
            width: 0.75rem;
        }
    }

    .select__body {
        top: 1.5rem;
        font-size: 1rem;
        right: 0;
        left: auto;
        width: 15rem;
    }

    .select__item {
        padding: 1rem;
        cursor: pointer;
        background: darken(#131313, 4%);
        border-bottom: 1px solid #1d1d1d;

        &:hover {
            background-color: #1d1d1d;
        }
    }

    &__header_verified {
        width: 1.25rem;
        height: 1.25rem;
    }

    &__buttons {
        display: flex;
        gap: 1.25rem;
        margin-top: 1rem;
    }

    &__body {
        margin-top: 1rem;
    }

    &__body_credits {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
    }

    label {
        border: none;
        background-color: transparent;
        font-size: 1rem;
        cursor: pointer;
        transition: 0.3s all ease;
        color: $neutral;
        margin-top: 0.25rem;
        display: inline-block;
    }

    input {
        margin-bottom: 0;
        font-size: 0.875rem;
        padding: 0.625rem 1rem;
        color: $light;
        background-color: #252525;
        border-radius: 0.125rem;
        transition: border 0.3s ease;
        border: 0.075rem solid #252525;
        width: 100%;
        appearance: none;

        &::placeholder {
            color: rgba($light, 0.5);
            text-transform: uppercase;
        }

        &:focus {
            border: 0.075rem solid $light;
            outline: none;
        }
    }

    button {
        font-size: 1rem;
        padding: 0.625rem 1.75rem;
        color: $light;
        background-color: transparent;
        border-radius: 0.125rem;
        transition: border 0.3s ease;
        width: 100%;
        border: 0.075rem solid #252525;
        cursor: pointer;
        text-align: center;
        transition: all 0.3s ease;

        &:hover {
            background-color: #252525;
            color: $light;
        }

        &:focus {
            outline: none;
        }

        &.delete {
            color: $secondary-accent;
        }
    }
}
