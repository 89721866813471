@keyframes codeAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

.hard-drive-access {
    background-color: #050505;
    color: #fff;
    font-family: "OCR B Std Medium", "Courier New", Courier, monospace;
    margin: 0;
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

#title {
    font-size: 24px;
    font-family: "OCR B Std Medium", "Courier New", Courier, monospace;
    margin-bottom: 20px;
    color: #fe6060;
}

#console {
    flex-grow: 1;
    overflow-y: auto;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 4rem;
    padding-bottom: 40px;
    font-family: "OCR B Std Medium", "Courier New", Courier, monospace;
}

#progress-container {
    position: fixed;
    bottom: 5rem;
    left: 20px;
    right: 20px;
    height: 4px;
    background-color: #333;
}

#progress-bar {
    height: 100%;
    width: 0;
    background-color: #2d5ffc;
    transition: width 0.3s ease-out;
}

#progress-text {
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 12px;
}

#initial-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 18px;
    max-width: 80%;
    font-family: "OCR B Std Medium", "Courier New", Courier, monospace;
}

.code-line {
    animation: codeAppear 0.3s ease forwards;
    opacity: 0;
    margin-bottom: 0.5rem;

    .arrow {
        color: #2d5ffc;
        margin-right: 0.5rem;
    }
}

.status {
    font-size: 16px;
    opacity: 0.8;
    animation: pulse 2s infinite ease-in-out;
    position: fixed;
    bottom: 5rem;
    left: 2rem;
}
