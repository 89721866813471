@use "../../../colors.scss" as *;

.payments_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 10;

    label {
        font-size: 1.2rem;
        font-weight: 600;
        color: white;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: rgba(black, 0.5);
    }

    &__container {
        position: relative;
        z-index: 2;
        background-color: $container-bg;
        border: 0.06rem solid $neutral;
        max-width: 45rem;
        width: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-height: 100%;
        overflow: auto;
    }

    .close {
        position: absolute;
        top: 3rem;
        right: 3rem;
    }

    &__users {
        max-height: 15rem;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__user {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        border: 0.06rem solid $neutral;
        cursor: pointer;

        &:not(.payments_modal__user--contributor):hover {
            background-color: lighten($container-bg, 5%);
        }

        &.active {
            border-color: $accent;
        }

        img {
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            border-radius: 50%;
        }

        h3 {
            font-size: 1.2rem;
            font-weight: 600;
            color: white;
        }

        p {
            font-size: 1rem;
            color: $neutral;
        }

        &--contributor {
            cursor: default;
        }
    }

    &__input_group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__revenue_share {
        margin-left: auto;
        display: flex;
        color: $neutral;
        align-items: center;
        gap: 0.5rem;

        input {
            width: 6rem;
            text-align: right;
            color: $accent;
            padding: 0.5rem;

            &:focus {
                border-color: $accent;
                outline: none;
            }
        }
    }

    &__error {
        color: $secondary-accent;
        font-size: 1rem;
    }
}
