@use "./colors.scss" as *;

@media (max-width: 1200px) {
    .songs_settings {
        grid-template-columns: 1fr 1fr;
    }

    .player_buttons__row {
        min-width: 0;
    }

    .remix_info__details img {
        width: 12rem;
        height: 12rem;
    }

    .remix_info__details {
        flex-wrap: wrap;
    }

    .player_controls__img {
        display: none;
    }

    .player_controls__music_img {
        display: block;
        margin-top: auto;
    }

    .player_controls__mobile_controls {
        display: flex;
        gap: 2rem;

        img {
            width: 2.25rem;
            height: 2.25rem;
        }
    }

    .player_controls__play img {
        width: 5.8rem;
        height: 5.8rem;
    }

    .player_controls {
        flex-direction: column;
    }

    .player_controls__mobile {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        align-items: center;
    }

    .player_controls__play {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        flex: 1;

        p {
            margin: 0;
            padding: 0;
            font-size: 0.75rem;
        }
    }

    .player_buttons {
        padding: 0;
    }

    #initial-message {
        width: 100%;
        font-size: 16px;
    }

    #title {
        font-size: 18px;
    }

    .nav img {
        width: 1rem;
    }

    .users__grid {
        gap: 1rem;
        grid-template-columns: repeat(2, calc(100% / 2 - 0.5rem));
    }

    .payments__container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .analytics__stats {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 900px) {
    ::-webkit-scrollbar {
        display: none;
    }

    .player_controls__generate {
        display: inline;
        cursor: pointer;
        width: 7rem;
    }

    .subscribe__header .app_header:nth-child(2) {
        display: none;
    }

    .content_container > .nav {
        display: flex;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
    }

    .subscribe_body__tiles {
        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;
        padding-bottom: 2rem;
    }

    .subsribe_body__row:nth-child(2) .app_header {
        text-align: left;
        color: $light;
    }
    .songs_settings {
        grid-template-columns: 1fr;
    }

    .ai_grid {
        grid-template-columns: 1fr 1fr;
    }

    .player_buttons__row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.5rem;
        width: 100%;

        p {
            font-size: 0.45rem;
        }
    }

    .player_buttons {
        width: auto;
        margin-left: auto;
        margin-right: 0;
        min-width: 0;
    }

    .player_button__num {
        width: 4rem;
        height: 4.2rem;
    }

    .player_next_back {
        display: none;
    }

    .player_button__generate {
        display: none;
    }

    .active_song .close {
        display: none;
    }

    .close {
        top: 1.5rem;
        right: 0;
    }

    .col__1 {
        padding-bottom: 5rem;
    }

    .col__2 {
        position: absolute;
        background-color: $container-bg;
        width: 100%;
        bottom: -0.25rem;
        left: 0;
        height: 6rem;
        z-index: 9;
        padding: 0;
        border-top: 1px solid lighten($container-bg, 5%);

        &.generate {
            height: 7rem;
        }

        .song_preview {
            padding-top: 0.75rem;
            padding-right: 1.25rem;
        }

        .nav {
            display: none;
        }

        .app_header {
            display: none;
        }

        .song_preview__img_container {
            display: none;
        }

        h3 {
            font-size: 1rem;
        }

        p {
            font-size: 0.75rem;
        }
    }

    .remix_info__details h3 {
        font-size: 1.25rem;
    }

    .remix_info {
        padding-bottom: 8rem;
    }

    .content_container hr {
        display: none;
    }

    .col__1 {
        padding-right: 0;
    }

    .song_comments,
    .post_comments {
        .close {
            display: none;
        }
    }

    .comment_form,
    .post_form {
        padding: 0;
        padding-left: 2rem;
        flex-direction: row-reverse;
    }

    .comment_form__publish img,
    .post_form__publish img {
        width: 3rem;
        height: 3rem;
    }

    .comment_form__publish p,
    .post_form__publish p {
        margin-top: 0.5rem;
        font-size: 0.6rem;
    }

    .comment_form textarea,
    .post_form textarea {
        width: 100%;
    }

    .users__grid {
        gap: 1rem;
        grid-template-columns: 1fr;
    }

    .subscribe_body__grid {
        grid-template-columns: 1fr;
    }

    .analytics__lists {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width: 700px) {
    .energy_modal__item {
        grid-template-columns: 1fr 1fr;
    }

    .energy_modal__container {
        padding: 3rem 1rem;
    }

    .analytics__header .select:nth-child(2),
    .payments__header .select:nth-child(2) {
        min-width: 14rem;
    }
}

@media (max-width: 600px) {
    .player_buttons {
        padding-left: 1rem;
    }

    .post_modal__container {
        padding: 1.5rem;
        z-index: 10;
    }

    .remix_info__buttons {
        grid-template-columns: 1fr;
    }

    .remix_info__details {
        flex-direction: column;
        align-items: flex-start;
    }

    .comment_form,
    .post_form {
        padding: 0;
        padding-left: 2rem;
        flex-direction: column;
    }

    .post_modal__img {
        width: 100%;
    }

    .comment_form textarea {
        padding: 1rem;
        font-size: 0.75rem;
    }

    .post_form textarea {
        padding: 1rem;
        padding-bottom: 2rem;
        font-size: 0.75rem;
    }

    .post_form__text {
        width: 100%;
    }

    .post_form__icons {
        padding: 0.25rem 1rem 0.5rem 1rem;

        img {
            width: 1.5rem;
        }
    }

    .song_tile {
        gap: 0.75rem;
    }

    .comment__par {
        font-size: 0.8rem;
    }

    .song_tile__header {
        font-size: 1rem;
    }

    .song_tile__img {
        width: 3rem;
        height: 3rem;
    }

    .song_tile__num {
        font-size: 1rem;
        width: 1.3rem;
    }

    .song_tile__artist {
        font-size: 0.75rem;
    }

    .player_controls {
        margin: 0;
    }

    .auth__header a {
        font-size: 1.5rem;
    }

    .auth__header {
        position: relative;
    }

    .auth {
        justify-content: flex-start;
        gap: 2rem;
    }

    .auth__form p {
        font-size: 0.75rem;
    }

    .google {
        margin-top: 1rem;
    }

    .content_container {
        padding: 1rem;
    }

    .auth .button {
        position: fixed;
        bottom: 5rem;
        left: 2rem;
        right: 2rem;
        width: auto;
    }

    .notification {
        font-size: 0.9rem;
        height: 1rem;
    }

    .subscribe_body .app_header {
        margin-bottom: 0.5rem;
    }

    .app_header {
        font-size: 1.5rem;
    }

    .subscribe_tile {
        gap: 1rem;

        img {
            width: 5rem;
            height: 5rem;
            border-radius: 0.25rem;
        }

        h3 {
            font-size: 1.5rem;
            margin-bottom: 0;
        }

        p {
            font-size: 0.9rem;
        }

        &:not(.subscribe_tile--active):hover {
            outline: 0.125rem solid rgba($neutral, 0.25);
        }

        &--active {
            outline: 0.125rem solid $accent;
        }
    }

    .account__img {
        width: 18rem;
        height: 18rem;
    }

    .menu_item {
        padding-left: 0;
        padding-right: 0;
    }

    .user {
        flex-direction: column;
        gap: 1rem;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .song_form__container {
        flex-direction: column;
    }

    .edit_song {
        padding-bottom: 2rem;
    }

    .ai_grid {
        grid-template-columns: 1fr;
    }

    .ai_inputs {
        flex-direction: column;
    }

    .player_controls__play p {
        font-size: 0.5rem;
    }

    .user__buttons {
        flex-direction: column;
        gap: 0.5rem;

        button {
            font-size: 0.75rem;
        }
    }

    .user {
        overflow: hidden;
    }

    .user__body_credits {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;

        label {
            font-size: 0.75rem;
        }
    }

    .user__header_img {
        width: 3rem;
        height: 3rem;
    }

    .user h3 {
        font-size: 1rem;
    }

    .user__header_left {
        gap: 0.75rem;
        width: 100%;
    }

    .user__header {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .payments__header,
    .payments__data_cells,
    .payments__buttons,
    .analytics__header,
    .analytics__stats {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .analytics__list h4 {
        font-size: 1rem;
    }

    .analytics__header .select:nth-child(2),
    .payments__header .select:nth-child(2) {
        min-width: 0;
    }
}

@media (max-width: 450px) {
    .notification {
        font-size: 0.6rem;
        margin-bottom: 0.5rem;
        height: 0.6rem;
    }

    .energy_modal__buttons {
        flex-direction: column;
    }
}

@media (max-height: 800px) and (min-width: 900px) {
    .player_header {
        display: none;
    }
}

@media (max-height: 700px) and (min-width: 900px) {
    .footer {
        display: none;
    }
}
