@use "../../colors.scss" as *;

.button {
    font-size: 1rem;
    padding: 0.625rem 1.75rem;
    color: $light;
    background-color: transparent;
    border-radius: 0.125rem;
    transition: border 0.3s ease;
    width: 100%;
    border: 0.06rem solid $light;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;

    &:not(:disabled):hover {
        background-color: $light;
        color: $container-bg;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}
