@use "../../../colors.scss" as *;

.subscribe_tile {
    padding: 0.875rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    border-radius: 0.125rem;
    transition: 0.2s ease all;
    overflow: hidden;

    &:not(.subscribe_tile--no_hover) {
        cursor: pointer;
    }

    div {
        flex: 1;
        overflow: hidden;
    }

    img {
        width: 7.5rem;
        height: 7.5rem;
        border-radius: 0.25rem;
    }

    h3 {
        color: white;
        font-size: 1.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.5rem;
    }

    p {
        font-size: 1rem;
        color: $neutral;
        line-height: 120%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:not(.subscribe_tile--active):not(.subscribe_tile--no_hover):hover {
        outline: 0.125rem solid rgba($neutral, 0.25);
    }

    &--active {
        outline: 0.125rem solid $accent;
    }

    p.subscribe_tile__cancelled {
        color: $secondary-accent;
    }

    p.subscribe_tile__cancel {
        color: $accent;
        cursor: pointer;
    }
}
